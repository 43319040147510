import React from 'react'
import { Table } from 'reactstrap'
import { convertDigitalValueToLabel } from '../../../../utilities/stringFormatting'

export default function ResultHTTPSThroughput({ result }) {
  return (
    <div>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>URL</th>
            <th>Filesize</th>
            <th>Time</th>
            <th>Bandwidth</th>
            <th>Succeeded</th>
          </tr>
        </thead>
        <tbody>
          {result.list.map((data, index) => (
            <tr key={index}>
              <td>{data.url}</td>
              <td>{data.file_size_B ? convertDigitalValueToLabel(data.file_size_B, 'B') : '-'}</td>
              <td>{data.time_ms ? `${Math.round(data.time_ms * 100) / 100} ms` : '-'}</td>
              <td>{data.bandwidth_MiB_s ? `${Math.round(data.bandwidth_MiB_s * 8 * 100) / 100} MiB/s` : '-'}</td>
              <td style={{ color: data.succeeded ? 'green' : 'red' }}>{data.succeeded ? 'PASS' : 'FAILED'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
