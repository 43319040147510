import React from 'react'
import { NavLink } from 'react-router-dom'
import {
  FaTachometerAlt,
  FaMap,
  FaChartLine,
  FaStethoscope,
  FaCog,
  FaCubes,
  FaUsers,
  FaUser,
  FaAddressCard,
  FaRegCalendarCheck,
  FaFileAlt,
  FaCloud,
  FaUpload,
  FaBell,
  FaCube,
  FaRegFileAlt
} from 'react-icons/fa'
import mini_logo from '../../assets/logo.svg'
import vutp_logo from '../../assets/logo_vutp_demo.png'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const isAdmin = useSelector(state => state.auth.is_superuser)

  return (
    <aside className="main-sidebar sidebar-dark-info elevation-4">
      <div className="logo-vutp overflow-hidden" id="sidebar-roof">
        <a href="/app/user/dashboard" className="notransition nav-item" id="vutp-logo-link">
          <img src={mini_logo} alt="netrsr logo" style={{ height: '31px', marginBottom: '7px' }} />
          <img src={vutp_logo} alt="vutp logo" style={{ height: '31px' }} />
        </a>
      </div>
      <div className="sidebar" style={{ marginTop: '0px' }}>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column nav-child-indent" data-widget="treeview" role="menu">
            <li className="nav-header">OVERVIEW</li>
            <li className="nav-item">
              <NavLink to="/dashboard" className="nav-link">
                <FaTachometerAlt /> <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/map" className="nav-link text">
                <FaMap /> <p>Map</p>
              </NavLink>
            </li>
            <li className="nav-header">START TEST</li>
            <li className="nav-item">
              <NavLink to="/test-suite" className="nav-link">
                <FaCubes /> <p>Test Suite</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/presets" className="nav-link">
                <FaCube /> <p>Presets</p>
              </NavLink>
            </li>
            <li className="nav-header">RESULTS</li>
            <li className="nav-item">
              <NavLink to="/results/" className="nav-link">
                <FaChartLine /> <p>Results</p>
              </NavLink>
            </li>
            <li className="nav-header">SETTINGS</li>
            <li className="nav-item">
              <NavLink to="/test-agents" className="nav-link">
                <FaStethoscope /> <p>Test Agents</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/notifications" className="nav-link">
                <FaBell /> <p>Notifications</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/account" className="nav-link">
                <FaCog /> <p>Account</p>
              </NavLink>
            </li>
            <li className="nav-header">SUPPORT</li>
            <li className="nav-item">
              <a href="https://docs.vutp.netrsr.com/en" target="_blank" rel="noopener noreferrer" className="nav-link">
                <FaRegFileAlt /> <p>Documentation</p>
              </a>
            </li>
            {isAdmin && (
              <>
                <li className="nav-header">ADMIN SETTINGS</li>
                <li className="nav-item">
                  <NavLink to="/users" className="nav-link">
                    <FaUser /> <p>Users</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/groups" className="nav-link">
                    <FaUsers /> <p>Groups</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/policies" className="nav-link">
                    <FaAddressCard /> <p>Policies</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/subscriptions" className="nav-link">
                    <FaRegCalendarCheck /> <p>Subscriptions</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/cloud_configs" className="nav-link">
                    <FaCloud /> <p>Cloud Configs</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/updates" className="nav-link">
                    <FaUpload /> <p>Updates</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/logs" className="nav-link">
                    <FaFileAlt /> <p>Logs</p>
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      {/*<div style={{ position: 'absolute', bottom: 10, left: 20, color: '#c2c7d0', fontStyle: 'italic' }}>Copyright © 2019 NetResearch</div>*/}
    </aside>
  )
}

export default Sidebar
