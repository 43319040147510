import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { CardTools } from '../../../components'
import { PresetModal } from '../../../containers'
import classnames from 'classnames'
import { DragDropContext } from 'react-beautiful-dnd'
import { FaAngleRight, FaStar } from 'react-icons/fa'
import TestList from './TestList'
import TestSuiteStep from './TestSuiteStep'
import { getNextOrdinalNumber } from '../../../utilities/mathOperations'
import { ToastContainer, toast } from 'react-toastify'
import * as actions from '../../../store/actions'
import TestSuiteConfigurator from '../../../context/TestSuiteConfiguratorContext'

export default function TestSuite() {
  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const categories = useSelector(state => state.test_categories.data)
  const testsDetails = useSelector(state => state.task_types.data)
  const account = useSelector(state => state.account.data)
  const topTests = useSelector(state => state.top_tests.data)
  const [favoritesTests, setFavoritesTests] = useState([])
  const [activeTab, setActiveTab] = useState('favorites')

  const { steps, setSteps, setSelTestIndexes, selectedBlock, setSelectedBlock, globalLocations, presetId } = useContext(
    TestSuiteConfigurator
  )

  useEffect(() => {
    dispatch(actions.fetchDataParams(token, 'TOP_TESTS', { limit: 8 }))
  }, [])

  useEffect(() => {
    let favoritesTests = []
    topTests &&
      testsDetails &&
      topTests.forEach(topTest =>
        favoritesTests.push(testsDetails.find(testDetails => testDetails.name === topTest.name))
      )
    setFavoritesTests(favoritesTests)
  }, [topTests, testsDetails])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const [removed] = list.splice(startIndex, 1)
    list.splice(endIndex, 0, removed)
    return list
  }

  const copy = (source, destination, droppableSource, droppableDestination) => {
    const item = source[droppableSource.index]
    const defaultForm = defaultTestForm(testsDetails.find(test => test.id === item.id))
    destination.splice(droppableDestination.index, 0, { ...defaultForm, tile_id: uuid() })
    return destination
  }

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source)
    const destClone = Array.from(destination)
    const [removed] = sourceClone.splice(droppableSource.index, 1)
    destClone.splice(droppableDestination.index, 0, removed)
    const result = {}
    result[droppableSource.droppableId] = sourceClone
    result[droppableDestination.droppableId] = destClone
    return result
  }

  function onDragEnd({ source, destination }) {
    if (!destination) {
      return
    }
    let _steps = [...steps]

    if (source.droppableId === destination.droppableId) {
      _steps = _steps.map((step, index) =>
        index === parseInt(destination.droppableId)
          ? { tests: reorder(steps[parseInt(destination.droppableId)].tests, source.index, destination.index) }
          : step
      )
    } else if (source.droppableId.includes('TESTS_LIST')) {
      if (!checkTestSuiteLimits(_steps, destination.droppableId)) {
        _steps = _steps.map((step, index) =>
          index === parseInt(destination.droppableId)
            ? {
                tests: copy(
                  activeTab !== 'favorites' ? getItemsList(activeTab) : favoritesTests,
                  steps[parseInt(destination.droppableId)].tests,
                  source,
                  destination
                )
              }
            : step
        )
      }
    } else if (!isNaN(destination.droppableId)) {
      const move_result = move(
        _steps[parseInt(source.droppableId)].tests,
        _steps[parseInt(destination.droppableId)].tests,
        source,
        destination
      )
      Object.keys(move_result).map(
        result_key =>
          (_steps = _steps.map((step, index) =>
            index === parseInt(result_key) ? { tests: move_result[result_key] } : step
          ))
      )
    }
    _steps = automaticRemoveAndAddSteps(_steps)

    _setSteps(_steps)
  }

  function _setSteps(steps) {
    setSteps(steps)
    setSelTestIndexes(steps)
  }

  function checkTestSuiteLimits(steps, stageId) {
    let limitExceeded = false
    if (account && account.configuration_limits) {
      if (
        Number.isInteger(account.configuration_limits.test_stage_count_limit) &&
        account.configuration_limits.test_stage_count_limit <= parseInt(stageId)
      ) {
        toast.error(
          `You have reached the maximum number of test suite steps (${account.configuration_limits.test_stage_count_limit}). If you want to increase the limit, please contact the administrator.`
        )
        limitExceeded = true
      } else if (
        Number.isInteger(account.configuration_limits.test_stage_size_limit) &&
        account.configuration_limits.test_stage_size_limit <= steps[stageId].tests.length
      ) {
        toast.error(
          `You have reached the maximum number of test blocks in test suite step (${account.configuration_limits.test_stage_size_limit}). If you want to increase the limit, please contact the administrator.`
        )
        limitExceeded = true
      } else {
        let testsCount = 0
        steps.forEach(step => (testsCount += step.tests.length))
        if (
          Number.isInteger(account.configuration_limits.test_block_count_limit) &&
          account.configuration_limits.test_block_count_limit <= testsCount
        ) {
          toast.error(
            `You have reached the maximum number of test blocks in test suite (${account.configuration_limits.test_block_count_limit}). If you want to increase the limit, please contact the administrator.`
          )
          limitExceeded = true
        }
      }
    }
    return limitExceeded
  }

  function removeEmptySteps(_steps) {
    if (_steps.length > 0 && _steps[_steps.length - 1].tests.length === 0) {
      _steps.splice(-1, 1)
      removeEmptySteps(_steps)
    }
    return _steps
  }

  function automaticRemoveAndAddSteps(_steps) {
    _steps = removeEmptySteps(_steps)
    _steps.push({ tests: [] })
    return _steps
  }

  function defaultTestForm(testDetails) {
    let testsArray = []
    steps.forEach(step => testsArray.push(...step.tests))
    let nameSuffix = getNextOrdinalNumber(testsArray.map(test => test.name), testDetails.display_name)
    let test = {
      display_name: testDetails.display_name,
      task_type: testDetails.id,
      task_type_name: testDetails.name,
      category: testDetails.subcategories[0].category,
      subcategory: testDetails.subcategories[0].id,
      name: testDetails.display_name + nameSuffix,
      overrideLocations: false,
      locations: globalLocations,
      additional_options: {
        thresholds: {
          enabled: false,
          mode:
            testDetails.options &&
            testDetails.options.additional_options &&
            testDetails.options.additional_options.thresholds &&
            testDetails.options.additional_options.thresholds.enabled
              ? 'json'
              : 'python',
          configuration:
            testDetails.options &&
            testDetails.options.additional_options &&
            testDetails.options.additional_options.thresholds &&
            testDetails.options.additional_options.thresholds.enabled
              ? { type: 'threshold', threshold_type: 'gte', value: 0 }
              : undefined,
          code: ''
        },
        dedicated_instances: { enabled: false, quantity: 0 }
      },
      priority: 0,
      params: {}
    }
    if (testDetails && testDetails.options && testDetails.options.params) {
      testDetails.options.params.forEach(option => {
        test.params[option.name] =
          'initial' in option.attributes ? option.attributes.initial : option.type === 'multipleinput' ? [''] : null
      })
      if (
        testDetails.options.additional_options &&
        testDetails.options.additional_options.thresholds &&
        testDetails.options.additional_options.thresholds.params &&
        testDetails.options.additional_options.thresholds.params.length > 0
      ) {
        test.additional_options.thresholds.configuration.variable =
          testDetails.options.additional_options.thresholds.params[0].param
      }
    }
    test.additional_options.thresholds.configuration = JSON.stringify(
      test.additional_options.thresholds.configuration,
      null,
      '\t'
    )
    return test
  }

  function removeItem(e, step_number, item_index) {
    let _steps = [...steps]
    if (selectedBlock && selectedBlock.tile_id === steps[step_number].tests[item_index].tile_id) {
      setSelectedBlock(null)
    }
    _steps[step_number].tests.splice(item_index, 1)
    _steps = automaticRemoveAndAddSteps(_steps)
    _setSteps(_steps)
    e.stopPropagation()
  }

  function removeStep(step_number) {
    let _steps = [...steps]
    _steps.splice(step_number, 1)
    _setSteps(_steps)
  }

  function getItemsList(categoryId) {
    let testIds = []
    let tests = []
    const category = categories.find(category => category.id === categoryId)
    if (category) {
      category.subcategories
        .sort((a, b) => a.sort_priority - b.sort_priority)
        .forEach(subcat => (testIds = testIds.concat(subcat.task_types)))
    }
    testsDetails && testIds.forEach(testId => tests.push(testsDetails.find(testDetails => testDetails.name === testId)))
    return tests
  }

  function testTileClick(e, item) {
    if (e.detail === 2) {
      const defaultForm = defaultTestForm(testsDetails.find(test => test.id === item.id))
      let _steps = [...steps]
      _steps[_steps.length - 1].tests.push({ ...defaultForm, tile_id: uuid() })
      _steps.push({ tests: [] })
      _setSteps(_steps)
    }
  }

  return (
    <div className="App">
      <ToastContainer position="top-right" newestOnTop />
      <Card className="top-line-info">
        <CardHeader className="no-border">
          <CardTitle>Test Suite</CardTitle>
          <CardTools>
            <PresetModal /> {presetId && <PresetModal />}
          </CardTools>
        </CardHeader>
        <CardBody className="p-0">
          <DragDropContext onDragEnd={onDragEnd}>
            <div style={{ overflowY: 'scroll', height: '55vh', width: '14.75rem', float: 'left' }}>
              <Nav tabs fill pills>
                <NavItem className="color-favorites">
                  <NavLink
                    className={classnames({ active: activeTab === 'favorites' })}
                    onClick={() => {
                      toggleTab('favorites')
                    }}
                  >
                    <span className="d-inline-flex align-items-center">
                      <FaStar /> Favorites
                    </span>
                  </NavLink>
                </NavItem>
                {categories &&
                  categories.map(category => (
                    <NavItem key={category.id} className={`color-${category.name}`}>
                      <NavLink
                        className={classnames({ active: activeTab === category.id })}
                        onClick={() => {
                          toggleTab(category.id)
                        }}
                      >
                        {category.display_name}
                      </NavLink>
                    </NavItem>
                  ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="favorites">
                  <TestList items={favoritesTests} tabId="favorites" testTileClick={testTileClick} />
                </TabPane>
                {categories &&
                  categories.map(category => (
                    <TabPane tabId={category.id} key={category.id}>
                      <TestList items={getItemsList(category.id)} tabId={category.id} testTileClick={testTileClick} />
                    </TabPane>
                  ))}
              </TabContent>
            </div>
            <div className="overflow-auto text-nowrap p-2" style={{ height: '55vh' }}>
              {steps.map((step, step_number) => (
                <Fragment key={step_number}>
                  <div key={step_number} style={{ width: '14.5rem', display: 'inline-block', verticalAlign: 'top' }}>
                    <TestSuiteStep
                      items={step.tests}
                      step_number={step_number}
                      isLastStep={step_number === steps.length - 1}
                      removeItem={removeItem}
                      removeStep={removeStep}
                    />
                  </div>
                  {step_number < steps.length - 1 && <FaAngleRight size={40} style={{ verticalAlign: 'middle' }} />}
                </Fragment>
              ))}
            </div>
          </DragDropContext>
        </CardBody>
      </Card>
    </div>
  )
}
